<template>
	<BaseLayout v-if="discount">
		<Toolbar @clickLeft="$router.go(-1)">
			<template v-slot:start>{{ $t('addvoucher.back') }}</template>
			{{ discount.name[currentLang] }}
		</Toolbar>
		<div class="flex flex-col items-center justify-center gap-5 p-5">
			<p>{{ discount.description[currentLang] }}</p>
			<p>{{ $t('addvoucher.exipiredDatevoucher') }}{{ moment(discount.endedAt).format('YYYY-MM-DD') }}</p>
		</div>
		<Footer v-if="discount.status === 'ACTIVE'">
			<Button :variant="$route.query.index ? 'danger' : 'primary'" @click="toggleCart" :disabled="isLoading">{{ $route.query.index ? $t('addvoucher.removevoucherFromCart') : $t('addvoucher.addvoucherToCart') }}</Button>
		</Footer>
	</BaseLayout>
	<Toast v-if="error" @close="error = null">{{ error.message }}</Toast>
</template>

<script>
import { storeToRefs } from 'pinia'
import { ref, computed, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useSharedStore } from '@/store/shared'
import { useCatalogStore } from '@/store/catalog'
import { useCartStore } from '@/store/cart'
import { saleApi } from 'gox-sdk'
import moment from 'moment'
export default {
	setup() {
		const route = useRoute()
		const router = useRouter()
		const store = useStore()
		const { currentLang } = storeToRefs(useSharedStore())
		const { voucher } = storeToRefs(useCatalogStore())
		const { currentCart } = storeToRefs(useCartStore())

		const id = computed(() => route.params.id)
		const discount = voucher
		// const inCart = computed(() => store.getters.inCartDiscount(id.value))

		const error = ref(null)
		const isLoading = computed(() => store.state.loadingState)

		onMounted(async () => {
			if (!id.value) return router.push('/home')
			try {
				store.commit('setLoadingState', { loadingState: true })
				error.value = null
				// discount.value = await store.dispatch('getDiscount', { id: id.value })
				store.commit('setLoadingState', { loadingState: false })
			} catch (e) {
				error.value = e
				router.push('/home')
				store.commit('setLoadingState', { loadingState: false })
				setTimeout(() => {
					error.value = null
				}, 10000)
			}
		})

		async function toggleCart() {
			try {
				store.commit('setLoadingState', { loadingState: true })
				error.value = null
				if (!route.query.index) await saleApi.createSaleVoucher(currentCart.value.id, { id: voucher.value.id })
				// await store.dispatch(inCart.value ? 'removeDiscountFromCart' : 'addDiscountToCart', { discount: discount.value, quantity: 1 })
				router.push('/cart')
				store.commit('setLoadingState', { loadingState: false })
			} catch (e) {
				console.error(e)
				error.value = e
				store.commit('setLoadingState', { loadingState: false })
				setTimeout(() => {
					error.value = null
				}, 10000)
			}
		}

		return {
			moment,
			currentLang,
			discount,
			// inCart,
			error,
			isLoading,
			toggleCart,
		}
	},
}
</script>
